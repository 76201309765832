import React, { forwardRef, HTMLProps, Ref } from 'react';
import Container from './container';
import * as S from './styles';
import { BaseButtonProps, ButtonColor, ButtonSize } from './index';

// Allow `any`s for awkward styled-components types
/* eslint @typescript-eslint/no-explicit-any: "off" */

export interface ClassicAnchorButtonProps extends HTMLProps<HTMLAnchorElement>, BaseButtonProps {
  title?: string;
}

// forwardRef is used to avoid issue listed here:
// https://github.com/zeit/next.js/issues/7915
const ClassicAnchorButton = forwardRef(
  (
    {
      as,
      badgeCount,
      buttonColor = ButtonColor.YELLOW,
      children,
      disabled,
      highlight = false,
      href,
      icon,
      buttonSize = ButtonSize.MEDIUM,
      buttonUse = 'default',
      secondary,
      vertical = false,
      lock = undefined,
      fitContent = false,
      ...rest
    }: ClassicAnchorButtonProps,
    ref?: Ref<HTMLAnchorElement>,
  ) => (
    <S.Variables
      styleProps={{
        disabled: !!disabled,
        $buttonColor: buttonColor,
        $secondary: !!secondary,
        $vertical: !!vertical,
        $highlight: !!highlight,
        $buttonUse: buttonUse,
        $buttonSize: buttonSize,
        $fitContent: fitContent,
      }}
    >
      <Container badgeCount={badgeCount} vertical={vertical} lock={lock}>
        <S.A
          disabled={!!disabled}
          ref={ref as any}
          as={as as any}
          href={href}
          $secondary={!!secondary}
          {...rest}
        >
          {icon && <S.Icon large={buttonUse !== 'default'} name={icon} $vertical={vertical} />}
          {children}
        </S.A>
      </Container>
    </S.Variables>
  ),
);

ClassicAnchorButton.displayName = 'ClassicAnchorButton';

export default ClassicAnchorButton;
