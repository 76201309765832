import defaultStyled, { css as defaultCss, DefaultTheme, keyframes } from 'styled-components';
import { Color, Theme } from 'src/utils/theme';
import createVariablesWrapper from '../../components/createVariablesWrapper';
import Loader from '../../components/Loader';
import IconComponent from '../../components/Icon';
import { active, disable, hover } from '../../components/utils';
import { SvgHelperWrapper } from '../../components';
import { ButtonColor, ButtonSize, ButtonType, ButtonUse } from './index';

export type ButtonStyleProps = {
  $buttonSize: ButtonSize;
  disabled: boolean;
  $secondary: boolean;
  $loading?: boolean;
  $buttonColor: ButtonColor;
  $buttonType?: ButtonType;
  $vertical: boolean;
  $highlight: boolean;
  $buttonUse: ButtonUse;
  $fitContent: boolean;
};

type ButtonStyleVariables = {
  fontSize: string;
  borderRadius: string;
  width: string;
  height: string;
  padding: string;
  backgroundColor: string;
  hoverBackgroundColor: string;
  activeBackgroundColor: string;
  borderColor: string;
  labelColor: string;
  fontColor: string;
  hoverFontColor: string;
  activeFontColor: string;
  animationTiming: string;
};

const styled = defaultStyled;
const css = defaultCss;

export type Selector = 'hover' | 'active';

type ApplySelector = (selector: Selector | null | undefined) => (color: string) => string;
const applySelector: ApplySelector = selector => color => {
  switch (selector) {
    case 'active':
      return active(color);
    case 'hover':
      return hover(color);
    case null:
      return color;
    case undefined:
      return color;
  }
};

type BaseColors = Record<
  'fontPrimary' | 'fontSecondary' | 'background' | 'highlight' | 'border' | 'label',
  Record<ButtonColor, Color>
>;

const baseColors: BaseColors = {
  fontPrimary: {
    [ButtonColor.BLACK]: 'primary99',
    [ButtonColor.BLUE]: 'primary99',
    [ButtonColor.CORAL]: 'primary99',
    [ButtonColor.GREEN]: 'secondary0',
    [ButtonColor.GREY]: 'neutral20',
    [ButtonColor.LIGHT_BLUE]: 'neutral20',
    [ButtonColor.PURPLE]: 'tertiary0',
    [ButtonColor.RED]: 'error20',
    [ButtonColor.TURQUOISE]: 'neutral20',
    [ButtonColor.WHITE]: 'neutral20',
    [ButtonColor.YELLOW]: 'secondary0',
  },
  fontSecondary: {
    [ButtonColor.BLACK]: 'secondary20',
    [ButtonColor.BLUE]: 'neutral20',
    [ButtonColor.CORAL]: 'neutral20',
    [ButtonColor.GREEN]: 'secondary0',
    [ButtonColor.GREY]: 'neutral20',
    [ButtonColor.LIGHT_BLUE]: 'neutral20',
    [ButtonColor.PURPLE]: 'tertiary0',
    [ButtonColor.RED]: 'error20',
    [ButtonColor.TURQUOISE]: 'neutral20',
    [ButtonColor.WHITE]: 'secondary80',
    [ButtonColor.YELLOW]: 'neutral20',
  },
  background: {
    [ButtonColor.BLACK]: 'secondary20',
    [ButtonColor.BLUE]: 'information10',
    [ButtonColor.CORAL]: 'warning50',
    [ButtonColor.GREEN]: 'success40',
    [ButtonColor.GREY]: 'neutral90',
    [ButtonColor.LIGHT_BLUE]: 'information80',
    [ButtonColor.PURPLE]: 'tertiary50',
    [ButtonColor.RED]: 'error60',
    [ButtonColor.TURQUOISE]: 'success80',
    [ButtonColor.WHITE]: 'primary99',
    [ButtonColor.YELLOW]: 'primary20',
  },
  highlight: {
    [ButtonColor.BLACK]: 'secondary50',
    [ButtonColor.BLUE]: 'information80',
    [ButtonColor.CORAL]: 'warning50',
    [ButtonColor.GREEN]: 'success80',
    [ButtonColor.GREY]: 'neutral98',
    [ButtonColor.LIGHT_BLUE]: 'information80',
    [ButtonColor.PURPLE]: 'tertiary70',
    [ButtonColor.RED]: 'error80',
    [ButtonColor.TURQUOISE]: 'success80',
    [ButtonColor.WHITE]: 'primary99',
    [ButtonColor.YELLOW]: 'primary20',
  },
  border: {
    [ButtonColor.BLACK]: 'secondary0',
    [ButtonColor.BLUE]: 'information10',
    [ButtonColor.CORAL]: 'warning50',
    [ButtonColor.GREEN]: 'success40',
    [ButtonColor.GREY]: 'secondary80',
    [ButtonColor.LIGHT_BLUE]: 'information80',
    [ButtonColor.RED]: 'error60',
    [ButtonColor.PURPLE]: 'tertiary50',
    [ButtonColor.TURQUOISE]: 'success80',
    [ButtonColor.WHITE]: 'secondary95',
    [ButtonColor.YELLOW]: 'primary20',
  },
  label: {
    [ButtonColor.BLACK]: 'secondary0',
    [ButtonColor.BLUE]: 'information10',
    [ButtonColor.CORAL]: 'warning50',
    [ButtonColor.GREEN]: 'success40',
    [ButtonColor.GREY]: 'neutral20',
    [ButtonColor.LIGHT_BLUE]: 'information80',
    [ButtonColor.RED]: 'error60',
    [ButtonColor.PURPLE]: 'tertiary50',
    [ButtonColor.TURQUOISE]: 'success80',
    [ButtonColor.WHITE]: 'secondary95',
    [ButtonColor.YELLOW]: 'primary20',
  },
};

const ButtonProps = (theme: DefaultTheme) => ({
  border: {
    [ButtonType.PRIMARY]: ``,
    [ButtonType.SECONDARY]: `${theme.co.secondary60}`,
    [ButtonType.TERTIARY]: `transparent`,
  },
  background: {
    [ButtonType.PRIMARY]: `${theme.co.primary20}`,
    [ButtonType.SECONDARY]: 'none',
    [ButtonType.TERTIARY]: 'none',
  },
  backgroundHoverAndFocus: {
    // focus states have a default browser outline (depending on the browser) but ALSO share hover states for more visibility
    [ButtonType.PRIMARY]: `${theme.co.primary40}`,
    [ButtonType.SECONDARY]: 'none',
    [ButtonType.TERTIARY]: 'none',
  },
  backgroundActive: {
    [ButtonType.PRIMARY]: `${theme.co.primary0}`,
    [ButtonType.SECONDARY]: 'none',
    [ButtonType.TERTIARY]: 'none',
  },
  color: {
    [ButtonType.PRIMARY]: `${theme.co.secondary0}`,
    [ButtonType.SECONDARY]: `${theme.co.secondary0}`,
    [ButtonType.TERTIARY]: `${theme.co.secondary0}`,
  },
  colorHoverAndFocus: {
    [ButtonType.PRIMARY]: ``,
    [ButtonType.SECONDARY]: `${theme.co.secondary60}`,
    [ButtonType.TERTIARY]: `${theme.co.secondary60}`,
  },
});

// Base/simple getters (those that use only color + props)

type BaseVarGetter = (theme: DefaultTheme) => (styleProps: ButtonStyleProps) => string;

const colorAccessor =
  (key: keyof BaseColors): BaseVarGetter =>
  ({ co }) =>
  ({ $buttonColor }) =>
    co[baseColors[key][$buttonColor]];

const getButtonType = (theme: DefaultTheme) => ButtonProps(theme);

export const getBaseFontColor: BaseVarGetter = theme => styleProps =>
  colorAccessor(styleProps.$secondary ? 'fontSecondary' : 'fontPrimary')(theme)(styleProps);

export const getBaseBackgroundColor = colorAccessor('background');
export const getHighlightColor = colorAccessor('highlight');
export const getBorderColor = colorAccessor('border');
export const getLabelColor = colorAccessor('label');

// Complex getters (those that also rely upon selectors)

type VarGetter = (
  theme: Theme,
) => (styleProps: ButtonStyleProps) => (selector?: Selector) => string;

export const getFontColor: VarGetter = theme => styleProps => selector => {
  const baseFontColor = getBaseFontColor(theme)(styleProps);
  const { disabled } = styleProps;
  if (disabled) return disable(baseFontColor);
  return applySelector(selector)(baseFontColor);
};

export const getBackgroundColor: VarGetter = theme => styleProps => selector => {
  const { $secondary, $highlight } = styleProps;
  if ($secondary && !$highlight) return 'none';

  const baseBackgroundColor =
    $secondary && $highlight
      ? getHighlightColor(theme)(styleProps)
      : getBaseBackgroundColor(theme)(styleProps);

  const { disabled } = styleProps;
  if (disabled) return disable(baseBackgroundColor);

  return applySelector(selector)(baseBackgroundColor);
};

export const getFontSize =
  ({ DEPRECATED_ty }: Theme) =>
  ({ $vertical, $buttonSize }: ButtonStyleProps): string => {
    if ($vertical)
      return $buttonSize === ButtonSize.LARGE ? DEPRECATED_ty.medium : DEPRECATED_ty.small;

    if ($buttonSize === ButtonSize.LARGE) return DEPRECATED_ty.large;
    if ($buttonSize === ButtonSize.EXTRA_SMALL) {
      return DEPRECATED_ty.small;
    }
    return DEPRECATED_ty.medium;
  };

export const getBorderRadius =
  ({ bo }: Theme) =>
  ($buttonUse: ButtonUse, $buttonSize: ButtonSize): string => {
    if ($buttonUse === 'ms') {
      return bo.round;
    }
    if ($buttonUse === 'scb') {
      return bo.mediumLarge;
    }
    if ($buttonSize === ButtonSize.EXTRA_SMALL) {
      return bo.large;
    } else if ([ButtonSize.SMALL, ButtonSize.LARGE].includes($buttonSize)) {
      return bo.medium;
    }
    return bo.small;
  };

const getWidth =
  ({ sz }: Theme) =>
  ($buttonUse: ButtonUse, $buttonSize: ButtonSize, $fitContent: boolean): string => {
    if ($fitContent) {
      return 'fit-content';
    }
    if ($buttonSize === ButtonSize.SMALL) {
      return 'auto';
    }
    if ($buttonUse === 'ms') {
      return sz.s16;
    }
    if ($buttonUse === 'scb') {
      return 'fit-content';
    }
    return '100%';
  };

export const getPadding =
  ({ sz }: Theme) =>
  ({
    $buttonUse,
    $vertical,
    $buttonSize,
  }: {
    $buttonUse: ButtonUse;
    $vertical: boolean;
    $buttonSize: ButtonSize;
  }): string => {
    if ($vertical) {
      return `${sz.s4} ${sz.s2}`;
    }
    if ($buttonUse === 'scb') {
      return `${sz.s4} ${sz.s6} ${sz.s4} ${sz.s4}`;
    }
    if ($buttonSize === ButtonSize.EXTRA_SMALL) {
      return `${sz.s1}`;
    }
    if ($buttonSize === ButtonSize.SMALL) {
      return `${sz.s3} ${sz.s8}`;
    }
    return sz.s4;
  };
export const Variables = createVariablesWrapper<ButtonStyleProps, ButtonStyleVariables>(
  styleProps => theme => {
    const { $vertical, $buttonUse, $buttonSize, $fitContent } = styleProps;
    const { sz } = theme;
    const fontSize = getFontSize(theme)(styleProps);
    const borderRadius = getBorderRadius(theme)($buttonUse, $buttonSize);
    const width = getWidth(theme)($buttonUse, $buttonSize, $fitContent);
    const height = $buttonUse !== 'default' ? sz.s16 : 'auto';
    const padding = getPadding(theme)({
      $vertical,
      $buttonUse,
      $buttonSize,
    });

    const getThemedFontColor = getFontColor(theme)(styleProps);
    const fontColor = getThemedFontColor();
    const activeFontColor = getThemedFontColor('active');
    const hoverFontColor = getThemedFontColor('hover');

    const getThemedBGColor = getBackgroundColor(theme)(styleProps);
    const backgroundColor = getThemedBGColor();
    const activeBackgroundColor = getThemedBGColor('active');
    const hoverBackgroundColor = getThemedBGColor('hover');

    const borderColor = getBorderColor(theme)(styleProps);
    const labelColor = getLabelColor(theme)(styleProps);

    return {
      fontSize,
      borderRadius,
      width,
      height,
      padding,
      backgroundColor,
      activeBackgroundColor,
      hoverBackgroundColor,
      borderColor,
      labelColor,
      fontColor,
      activeFontColor,
      hoverFontColor,
      animationTiming: '200ms cubic-bezier(0, 1, 0.75, 1)',
      ...theme,
    };
  },
);

interface ButtonProps {
  disabled: boolean;
  $secondary: boolean;
  $vertical: boolean;
  $buttonType: string;
}

const buttonCss = css<ButtonProps>`
  /* Shared Styles */
  align-items: center;
  background: ${({ theme }) => theme.backgroundColor};
  border-radius: ${({ theme }) => theme.borderRadius};
  color: ${({ theme }) => theme.fontColor};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  flex-basis: 100%;
  flex-direction: ${({ $vertical }) => ($vertical ? 'column' : 'row')};
  font-family: ${({ theme }) => theme.DEPRECATED_ty.openSans};
  font-size: ${({ theme }) => theme.fontSize};
  hyphens: auto;
  justify-content: center;
  line-height: 1.2;
  overflow-wrap: anywhere;
  padding: ${({ theme }) => theme.padding};
  position: relative;
  text-align: center;
  text-decoration: none;
  transition:
    opacity ${({ theme }) => theme.animationTiming},
    color 0.2s 0.1s ease-in-out;
  width: ${({ theme }) => theme.width};
  height: ${({ theme }) => theme.height};
  z-index: ${({ theme }) => theme.zi.z3};

  &:hover,
  &:focus,
  &:hover:visited {
    background-color: ${({ theme, $buttonType }) =>
      /* istanbul ignore next: styling hard to test */
      $buttonType
        ? getButtonType(theme).backgroundHoverAndFocus[$buttonType]
        : theme.hoverBackgroundColor};
    color: ${({ theme }) => theme.co.secondary0};
  }

  &:active,
  &:active:visited {
    box-shadow: none;
    color: ${({ theme }) => theme.co.secondary0};
    background-color: ${({ theme, $buttonType }) =>
      /* istanbul ignore next: styling hard to test */
      $buttonType
        ? getButtonType(theme).backgroundActive[$buttonType]
        : theme.activeBackgroundColor};
  }

  ${({ disabled, $secondary, theme }) =>
    $secondary
      ? css`
          border: ${theme.bo.mediumWidth} solid ${theme.borderColor};

          /* We fake the 3d effect using a box-shadow */
          box-shadow: 0 4px 0 ${theme.borderColor};
          font-weight: ${({ theme }) => theme.DEPRECATED_ty.semiBold};

          ${!disabled &&
          css`
            &:hover,
            &:focus {
              box-shadow: 0 ${theme.sz.s2} 0 ${theme.borderColor};
              transform: translateY(-4px);

              /* This is to avoid flickering when placing the cursor at the bottom, caused by the transform */
              &::after {
                content: '';
                width: 100%;
                position: absolute;
                top: 100%;
                height: 6px;
              }
            }
            &:active {
              box-shadow: 0 0 0 ${theme.borderColor};
              transform: translateY(4px);
            }
            &:active:focus {
              box-shadow: 0 0 0 ${theme.borderColor};
            }
          `}
        `
      : // Primary styles
        css`
          border: none;
          box-shadow: inset 0 -4px 0 rgba(0, 0, 0, 10%);
          font-weight: ${({ theme }) => theme.DEPRECATED_ty.bold};
        `}

  ${({ disabled, $secondary, theme }) =>
    // styles for non-multiple choice disabled buttons
    disabled &&
    !$secondary &&
    css`
      background-color: ${theme.co.secondary80};
      box-shadow: inset 0 -4px 0 rgba(0, 0, 0, 10%);

      &:hover,
      &:hover:visited {
        background-color: ${theme.co.secondary80};
      }

      &:active,
      &:active:visited {
        background-color: ${theme.co.secondary80};
      }
    `}
`;

export const Button = styled.button<ButtonProps>`
  ${buttonCss}
`;
export const A = styled.a<ButtonProps>`
  ${buttonCss}
`;

export const Container = styled.div<{ $vertical: boolean }>`
  ${({ theme, $vertical }) => !$vertical && `flex-basis: ${theme.width};`}
  z-index: ${props => props.theme.zi.z1};
  position: relative;
`;

export const BadgeCount = styled.div(
  ({ theme }) => css`
    background: ${theme.co.primary99};

    /* Border used here to make a trapezium */
    border-bottom: 0.03rem solid transparent;
    border-right: 1.6rem solid ${theme.co.neutral20};
    border-top: 0.3rem solid transparent;

    /* Make the cut-out effect with box-shadow */
    box-shadow: -0.15rem 0.09rem 0 ${theme.co.primary99};

    height: 1.4rem;
    left: 2.9rem;
    position: absolute;
    top: -0.5rem;
    transform: rotate(-10deg);
    width: 0;
    z-index: ${theme.zi.z5};
  `,
);

export const BadgeCenter = styled.div(
  ({ theme }) => css`
    display: flex;
    justify-content: center;
    position: relative;
    text-align: center;
    top: -0.045rem;
    width: 1.6rem;
    z-index: ${theme.zi.z6};

    span {
      color: ${theme.co.primary99};
      font-size: ${theme.DEPRECATED_ty.smaller};
      font-weight: ${theme.DEPRECATED_ty.bold};
    }
  `,
);

const lockCss = css`
  position: absolute;
  bottom: -0.1rem;
  left: 2.8rem;
  z-index: ${({ theme }) => theme.zi.z6};
  transform: rotate(3deg);
  &,
  svg {
    width: ${({ theme }) => theme.sz.s6};
    height: ${({ theme }) => theme.sz.s6};
  }
`;

export const LockIconShadow = styled(IconComponent)(
  ({ theme }) => css`
    svg {
      overflow: visible;
    }

    svg path {
      fill: ${theme.co.primary99};
      stroke-width: 0.7rem;
      stroke: ${theme.co.primary99};
    }

    ${lockCss}
  `,
);

export const LockIcon = styled(IconComponent)`
  ${lockCss}
`;

export const Icon = styled(IconComponent)<{
  $disabled?: boolean;
  iconColor?: Color;
  large: boolean;
  $vertical: boolean;
  isCompact?: boolean;
}>(
  ({ theme, $vertical, large, iconColor, $disabled, isCompact }) => css`
    display: inline-flex;
    align-items: center;
    color: ${iconColor && theme.co[iconColor]};

    ${$vertical ? `margin-bottom: ${theme.sz.s4}` : `margin: 0 ${theme.sz.s2}`};

    ${isCompact && `margin: 0;`};

    &,
    svg {
      width: ${large ? theme.sz.s10 : $vertical ? theme.sz.s8 : theme.sz.s6};
      height: ${large ? theme.sz.s10 : $vertical ? theme.sz.s8 : theme.sz.s6};
    }

    & svg * {
      fill: ${/* istanbul ignore next */ iconColor || theme.fontColor};
    }

    ${$disabled /* istanbul ignore next: untested branch of code, please test */ &&
    `& svg * {
        fill: ${theme.co.neutral30};
      }`}
  `,
);

export const Error = styled.span<{ $show: boolean }>(
  ({ theme, $show }) => css`
    font-weight: ${theme.DEPRECATED_ty.bold};
    font-size: ${theme.DEPRECATED_ty.smaller};
    text-align: center;
    position: absolute;
    width: ${theme.width};
    padding: calc(${theme.borderRadius} + ${theme.sz.s1}) ${theme.sz.s2} ${theme.sz.s1}
      ${theme.sz.s4};
    background-color: ${theme.co.error60};
    color: ${theme.co.neutral99};
    transition:
      transform 300ms cubic-bezier(0, 1, 0.75, 1),
      opacity 300ms cubic-bezier(0, 1, 0.75, 1);
    border-bottom-right-radius: ${theme.borderRadius};
    border-bottom-left-radius: ${theme.borderRadius};
    opacity: ${$show ? '1' : '0'};
    transform: ${$show ? 'none' : 'translateY(-3rem)'};
    display: ${$show ? 'unset' : 'none'};
  `,
);

export const ErrorContainer = styled.div(
  ({ theme }) => css`
    position: relative;
    bottom: 0;
    top: -${theme.borderRadius};
    display: flex;
    width: 100%;
    z-index: ${theme.zi.z2};
  `,
);

export const ButtonLoader = styled(Loader)`
  height: ${props => props.theme.sz.s6};
  margin-right: ${props => props.theme.sz.s4};
`;

export const ButtonChildren = styled.div<{ $withPositionLabel: boolean }>(
  ({ $withPositionLabel, theme }) => `
    flex-grow: 1;

    ${
      $withPositionLabel &&
      `
      @media(min-width: 40rem) {
        margin-left: ${theme.sz.s10};
        margin-right: ${theme.sz.s10};
      }
    `
    }
  `,
);

export const PositionLabel = styled.div(
  ({ theme }) => css`
    display: none;
    background-color: ${theme.labelColor};
    border-radius: ${theme.bo.extraSmall};
    color: ${theme.backgroundColor === 'none'
      ? // 'none' is not a valid property for color so set a fallback off-white
        theme.co.primary99
      : theme.backgroundColor};
    flex-shrink: 0;
    font-size: ${theme.DEPRECATED_ty.medium};
    width: ${theme.sz.s6};
    height: ${theme.sz.s6};
    justify-content: center;
    text-align: center;
    position: absolute;
    top: calc(50% - ${theme.sz.s3});
    left: ${theme.sz.s4};
    align-items: center;

    @media (min-width: 40rem) {
      display: flex;
    }
  `,
);

const pulse = keyframes`
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.1);
  }
`;

export const UnlockedBackground = styled(SvgHelperWrapper)(
  ({ theme }) => css`
    animation: ${pulse} infinite 0.75s alternate;
    height: 5rem;
    left: -${theme.sz.s2};
    position: absolute;
    top: -${theme.sz.s2};
    width: 5rem;
  `,
);
