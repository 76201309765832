import styled, { css } from 'styled-components';

export const Banner = styled.div(
  ({ theme }) => css`
    background-color: ${theme.co.information98};
    border-radius: ${theme.bo.mediumLarge};
    padding: ${theme.sz.s4};
    display: flex;
    align-items: flex-start;
    gap: ${theme.sz.s4};

    @media (max-width: ${theme.sz.md}) {
      gap: ${theme.sz.s2};
    }
  `,
);

export const Title = styled.div(
  ({ theme }) => css`
    ${theme.typography.heading.desktop.m};

    @media (max-width: ${theme.sz.md}) {
      ${theme.typography.heading.desktop.xs};
    }
  `,
);

export const Description = styled.div(
  ({ theme }) => css`
    ${theme.typography.paragraph.base};
    margin: ${theme.sz.s4} 0;

    @media (max-width: ${theme.sz.md}) {
      ${theme.typography.paragraph.m};
      margin: ${theme.sz.s2} 0;
    }
  `,
);

export const IconWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;

    svg {
      height: ${theme.sz.s20};
      width: ${theme.sz.s20};
    }

    @media (max-width: ${theme.sz.md}) {
      margin-right: ${theme.sz.s2};
      svg {
        height: ${theme.sz.s10};
        width: ${theme.sz.s10};
      }
    }
  `,
);

export const ButtonsWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: row-reverse;
    gap: ${theme.sz.s2};
    margin-top: ${theme.sz.s4};
  `,
);
