import React, { FC } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { LinkButton } from 'src/components/LinkButton';
import Icon from '../../components/Icon';
import * as S from './styles';

// N.B. Pure UGC users currently don't see this banner at all
const msgs = defineMessages({
  title: { id: 'hybrid_switch_home_banner_title' },
  cta: { id: 'hybrid_switch_home_banner_cta' },
});

export const hybridUserHubSpot: { [key: string]: string } = {
  ar: 'https://explore.memrise.com/ar/%D8%AE%D9%84%D9%8A%D8%B7-%D8%A5%D8%AA%D8%A7%D8%AD%D8%A9-%D8%AA%D9%82%D9%84%D9%8A%D8%AF%D9%8A%D8%A9-%D8%A5%D9%84%D9%89-%D9%85%D8%A8%D9%83%D8%B1%D8%A9',
  da: 'https://explore.memrise.com/da/klassisk-til-tidlig-adgang-hybrid',
  de: 'https://explore.memrise.com/de/von-klassisch-bis-early-access-hybrid',
  en: 'https://explore.memrise.com/classic-to-early-access-hybrid',
  'en-us': 'https://explore.memrise.com/classic-to-early-access-hybrid',
  es: 'https://explore.memrise.com/es/de-clasico-a-acceso-anticipado-hibrido',
  'es-mx': 'https://explore.memrise.com/es-mx/clasico-para-acceso-temprano-hibrido',
  fr: 'https://explore.memrise.com/fr/hybride-acces-classique-a-acces-anticipe',
  hi: 'https://explore.memrise.com/hi/%E0%A4%95%E0%A5%8D%E0%A4%B2%E0%A4%B8%E0%A4%95-%E0%A4%B8%E0%A5%87-%E0%A4%B0%E0%A4%B2%E0%A4%9C%E0%A4%BC-%E0%A4%B8%E0%A5%87-%E0%A4%AA%E0%A4%B9%E0%A4%B2%E0%A5%87-%E0%A4%90%E0%A4%95%E0%A5%8D%E0%A4%B8%E0%A5%87%E0%A4%B8-%E0%A4%B9%E0%A4%87%E0%A4%AC%E0%A5%8D%E0%A4%B0%E0%A4%A1',
  id: 'https://explore.memrise.com/id/klasik-ke-akses-dini-kombo',
  it: 'https://explore.memrise.com/it/da-classico-ad-accesso-anticipato-ibrido',
  ja: 'https://explore.memrise.com/ja/%E3%82%AF%E3%83%A9%E3%82%B7%E3%83%83%E3%82%AF-%E3%81%8B%E3%82%89-%E6%97%A9%E6%9C%9F-%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B9-%E3%83%8F%E3%82%A4%E3%83%96%E3%83%AA%E3%83%83%E3%83%89',
  ko: 'https://explore.memrise.com/ko/%ED%81%B4%EB%9E%98%EC%8B%9D%EB%B6%80%ED%84%B0-%EC%96%BC%EB%A6%AC-%EC%95%A1%EC%84%B8%EC%8A%A4%EA%B9%8C%EC%A7%80-%ED%95%98%EC%9D%B4%EB%B8%8C%EB%A6%AC%EB%93%9C',
  nl: 'https://explore.memrise.com/nl/klassiek-naar-vervroegde-toegang-hybride',
  no: 'https://explore.memrise.com/no/klassisk-til-tidlig-tilgang-hybrid',
  pl: 'https://explore.memrise.com/pl/z-klasycznego-do-wczesnego-dostepu-hybryda',
  'pt-br': 'https://explore.memrise.com/pt-br/classico-para-acesso-antecipado-hibrido',
  pt: 'https://explore.memrise.com/pt-pt/classico-para-acesso-antecipado-hibrido',
  ru: 'https://explore.memrise.com/ru/%D0%BA%D0%BB%D0%B0%D1%81%D1%81%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B8%D0%B9-%D0%BD%D0%B0-%D1%80%D0%B0%D0%BD%D0%BD%D0%B8%D0%B9-%D0%B4%D0%BE%D1%81%D1%82%D1%83%D0%BF-%D0%B3%D0%B8%D0%B1%D1%80%D0%B8%D0%B4%D0%BD%D1%8B%D0%B9',
  sv: 'https://explore.memrise.com/sv/klassisk-till-tidig-atkomst-hybrid',
  tr: 'https://explore.memrise.com/tr/klasikten-erken-erisime-hibrit',
  vi: 'https://explore.memrise.com/vi/thong-thuong-toi-truy-cap-som-hon-hop',
  'zh-cn':
    'https://explore.memrise.com/zh-hans/%E7%BB%8F%E5%85%B8-%E5%88%B0-%E6%8A%A2%E5%85%88-%E4%BD%93%E9%AA%8C-%E7%BB%93%E5%90%88',
  'zh-tw':
    'https://explore.memrise.com/zh-hant/%E7%B6%93%E5%85%B8-%E5%88%B0-%E6%90%B6%E5%85%88-%E9%AB%94%E9%A9%97-%E7%B5%90%E5%90%88',
};

export const MovingSoonBanner: FC<{
  onClick: () => void;
}> = ({ onClick }) => {
  const intl = useIntl();

  return (
    <S.Banner data-testid="MovingSoonBanner">
      <S.IconWrapper>
        <Icon name="rocket" />
      </S.IconWrapper>
      <div>
        <S.Title>{intl.formatMessage(msgs.title)}</S.Title>
        <S.ButtonsWrapper>
          <LinkButton buttonType="primary" buttonSize="medium" onClick={onClick}>
            {intl.formatMessage(msgs.cta)}
          </LinkButton>
        </S.ButtonsWrapper>
      </div>
    </S.Banner>
  );
};
