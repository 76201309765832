import styled, { css } from 'styled-components';
import _Image from 'next/image';
import ClassicLinkButton from '../ClassicButton/ClassicLinkButton';
import brushBackground from './brush.png';

export const BrushBG = styled.div(
  ({ theme }) => css`
    mask-image: url(${brushBackground});
    background-color: ${theme.co.primary20};
    width: 100%;
    mask-repeat: round;
    height: 3.5rem;
    margin-top: -3.5rem;
  `,
);

export const Title = styled.h2(
  ({ theme }) => css`
    font-size: ${theme.DEPRECATED_ty.largerStill};
    font-family: ${theme.DEPRECATED_ty.boing};
    text-align: center;
    color: ${theme.co.secondary20};
    padding: ${theme.sz.s2} ${theme.sz.s8};
    background: ${theme.co.primary20};
    margin: 0;
  `,
);

export const Body = styled.p(
  ({ theme }) => css`
    text-align: center;
    color: ${theme.co.secondary20};
    padding: ${theme.sz.s2} ${theme.sz.s8} ${theme.sz.s16} ${theme.sz.s8};
    background: ${theme.co.primary20};
    margin: 0;
  `,
);

export const SubscribeButton = styled(ClassicLinkButton)(
  ({ theme }) => css`
    position: relative;
    top: -${theme.sz.s8};
    max-width: 80%;
    margin: 0 auto;
  `,
);

export const Image = styled(_Image)`
  width: 100%;
  height: auto;
  object-fit: cover;
`;
