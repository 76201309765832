import React, { FC } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Modal } from 'src/components';
import LWL from 'images/modal-learn-with-locals.jpg';
import DW from 'images/modal-difficult-words.jpg';
import LS from 'images/modal-audio.jpg';
import { ButtonColor } from 'src/componentsClassic';
import { ROUTES } from 'src/constants/routes.const';
import * as S from './styles';

const msgs = defineMessages({
  videoTitle: {
    id: 'mtn_popup_title',
  },
  videoBody: {
    id: 'mtn_popup_description',
  },
  difficultWordsTitle: {
    id: 'popup_difficultWords_header',
  },
  difficultWordsBody: {
    id: 'popup_difficultWords_description',
  },
  audioTitle: {
    id: 'popup_listeningSkills_header',
  },
  audioBody: {
    id: 'popup_listeningSkills_description',
  },
  subscribe: {
    id: 'go_pro',
  },
});

type ProSessionType = 'difficult_words' | 'video' | 'listening_skills';

const sessionTypeMsgs: Record<
  ProSessionType,
  { title: Record<string, unknown>; body: Record<string, unknown> }
> = {
  video: {
    title: msgs.videoTitle,
    body: msgs.videoBody,
  },
  difficult_words: {
    title: msgs.difficultWordsTitle,
    body: msgs.difficultWordsBody,
  },
  listening_skills: {
    title: msgs.audioTitle,
    body: msgs.audioBody,
  },
};

const sessionImgs: Record<ProSessionType, string> = {
  video: LWL,
  difficult_words: DW,
  listening_skills: LS,
};

interface LockedModeUpsellProps {
  isOpen: boolean;
  handleClose: () => void;
  sessionType: ProSessionType;
}

const LockedModeUpsell: FC<LockedModeUpsellProps> = ({ isOpen, handleClose, sessionType }) => {
  const intl = useIntl();
  const { title, body } = sessionTypeMsgs[sessionType];
  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      closeIconType="cross"
      backgroundColor="primary99"
    >
      <S.Image
        src={sessionImgs[sessionType]}
        width={200}
        height={100}
        alt={sessionType}
        sizes="100vw"
      />
      <S.BrushBG />
      <S.Title>{intl.formatMessage(title)}</S.Title>
      <S.Body>{intl.formatMessage(body)}</S.Body>
      <S.SubscribeButton buttonColor={ButtonColor.BLUE} href={ROUTES.payment.plans}>
        {intl.formatMessage(msgs.subscribe)}
      </S.SubscribeButton>
    </Modal>
  );
};

export default LockedModeUpsell;
