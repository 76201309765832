import styled, { css } from 'styled-components';
import ClassicLinkButton from 'src/componentsClassic/ClassicButton/ClassicLinkButton';
import paintStroke from './paintStroke.png';

export const CardFooter = styled.div<{ $backgroundColor: string }>(
  ({ $backgroundColor, theme }) => css`
    mask-image: url('${paintStroke}');
    mask-repeat: no-repeat;
    mask-size: 100% 100%;
    background-color: ${$backgroundColor};
    background-repeat: no-repeat;
    background-size: cover;
    width: calc(100% + 6px);
    margin: -${theme.sz.s12} -3px -3px -3px;
    padding-top: ${theme.sz.s8};
    border-radius: ${theme.sz.s4};
    z-index: ${theme.zi.z1};

    @media (max-width: ${theme.sz.xl}) {
    }
  `,
);

export const FooterContent = styled.div(
  ({ theme }) => css`
    padding: ${theme.sz.s6};
    margin-bottom: -${theme.sz.s2};
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;

    @media (max-width: ${theme.sz.xl}) {
      padding-top: ${theme.sz.s16};
      flex-wrap: wrap;
    }
  `,
);

export const Text = styled.div(
  ({ theme }) => css`
    margin-bottom: ${theme.sz.s2};
    min-width: ${theme.sz.s20};
    margin-right: ${theme.sz.s8};
  `,
);

export const AdCardTitle = styled.div(
  ({ theme }) => css`
    font-weight: ${theme.DEPRECATED_ty.bold};
    font-size: ${theme.DEPRECATED_ty.large};
    position: relative;
    z-index: ${theme.zi.z2};
    padding-bottom: ${theme.sz.s1};
    user-select: none;
  `,
);

export const AdCardDescription = styled.div(
  ({ theme }) => css`
    font-size: ${theme.DEPRECATED_ty.medium};
    position: relative;
    z-index: ${theme.zi.z2};
    user-select: none;
  `,
);

export const CTAButton = styled(ClassicLinkButton)(
  ({ theme }) => css`
    width: ${theme.sz.s48};
    font-size: ${theme.DEPRECATED_ty.small};
    margin-bottom: ${theme.sz.s2};
  `,
);
