import React, { FC } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import * as S from './styles';

const msgs = defineMessages({
  adCardTitle: {
    id: 'eos_advert_card_title',
    defaultMessage: 'Support Memrise',
  },
  adCardDescription: {
    id: 'eos_advert_card_description',
    defaultMessage: 'Go ad free and unlock more features!',
  },
  subscribePremium: {
    id: 'eos_advert_card_cta',
    defaultMessage: 'Subscribe now',
  },
});

interface Props {
  backgroundColor: string;
}

export const AdFooter: FC<Props> = ({ backgroundColor }) => {
  const intl = useIntl();

  return (
    <S.CardFooter $backgroundColor={backgroundColor}>
      <S.FooterContent>
        <S.Text>
          <S.AdCardTitle>{intl.formatMessage(msgs.adCardTitle)}</S.AdCardTitle>
          <S.AdCardDescription>{intl.formatMessage(msgs.adCardDescription)}</S.AdCardDescription>
        </S.Text>
        <S.CTAButton
          buttonSize="small"
          href="/premium/"
          data-testid="googleAdCTAButton"
          target="_blank"
        >
          {intl.formatMessage(msgs.subscribePremium)}
        </S.CTAButton>
      </S.FooterContent>
    </S.CardFooter>
  );
};
