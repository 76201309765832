import React, { FC } from 'react';
import * as S from './styles';

type PillColor = 'grey' | 'green';

interface PillProps {
  children: string;
  color?: PillColor;
}

const Pill: FC<PillProps> = ({ children, color, ...props }) => {
  return (
    <S.PillContainer {...props} color={color}>
      <span>{children}</span>
    </S.PillContainer>
  );
};

export default Pill;
